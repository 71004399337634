import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/blog-layout.js";
import { Link } from "gatsby";
import { Box } from "theme-ui";
import SEO from "../../components/seo";
import CodeBlock from "../../components/code-block";
import { StaticImage } from "gatsby-plugin-image";
import { BlogDetails } from "../../components/blog-details";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="College, Bootcamp, or Self-Taught" mdxType="SEO" />
    <h1>{`College, Bootcamp, or Self-Taught?`}</h1>
    <BlogDetails date="April 20th, 2021" time="3 min read" mdxType="BlogDetails" />
    <StaticImage style={{
      display: 'none'
    }} alt="test" src="../images/college-bootcamp-thumbnail.png" mdxType="StaticImage" />
    <Box pb={10} mdxType="Box" />
    <p>{`This is just a `}<a href="http://example.com/" target="_blank">{`test`}</a>{` to see if it works. Still building the site. `}</p>
    <br />
    <p>{`You can use `}<inlineCode parentName="p">{`useEffect()`}</inlineCode>{` as a dope React hook.`}</p>
    <p>{`You can use `}<inlineCode parentName="p">{`useState()`}</inlineCode>{` as a dope React hook.`}</p>
    <p>{`You can use `}<inlineCode parentName="p">{`useReducer()`}</inlineCode>{` as a dope React hook.`}</p>
    <p>{`You can use `}<inlineCode parentName="p">{`useCallback()`}</inlineCode>{` as a dope React hook.`}</p>
    <br />
    <br />
    <h2>{`Here is some code`}</h2>
    <CodeBlock language={"jsx"} value={`import { Link } from "gatsby";
import React from "react";
import { jsx, Box } from "theme-ui";
import { StaticImage } from "gatsby-plugin-image";
//
const Header = ({ siteTitle }) => (
  <div>
    <div className="dark">
      <div className="logo_wrapper">
        <Link
          to="/"
          style={{
            color: "white",
            textDecoration: "none"
          }}
        >
          <Box
            sx={{
              maxWidth: ["85%", 450],
              mx: "auto",
              mt: [2, 0],
              transition: ["none", "all 0.5s"],
              "&:hover": { transform: ["none", "scale(1.1, 1.1)"] }
            }}
          >
            <StaticImage
              src="../images/tde_logo_long_dark.png"
              alt="the dev experience - learn to code"
            />
          </Box>
        </Link>
      </div>
    </div>
    <div className="nav_border">
      <div className="nav_grid">
        <div>
          <Link to="/courses" className="nav-item red">
            Courses
          </Link>
        </div>
        <div>
          <Link to="/blog" className="nav-item yellow">
            Blog
          </Link>
        </div>
        <div>
          <Link to="/about" className="nav-item blue">
            About
          </Link>
        </div>
        <div>
          <Link to="/hire" className="nav-item green">
            Hire
          </Link>
        </div>
      </div>
    </div>
  </div>
);
//
export default Header;`} mdxType="CodeBlock" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      